@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.g-root {
  --g-font-family-sans: "Inter", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  --g-font-family-monospace: "Menlo", "Monaco", "Consolas", "Ubuntu Mono", "Liberation Mono",
      "DejaVu Sans Mono", "Courier New", "Courier", monospace;
  --g-text-body-font-family: var(--g-font-family-sans);
  --g-text-caption-font-family: var(--g-font-family-sans);
  --g-text-header-font-family: var(--g-font-family-sans);
  --g-text-subheader-font-family: var(--g-font-family-sans);
  --g-text-display-font-family: var(--g-font-family-sans);
  --g-text-code-font-family: var(--g-font-family-monospace);
  --g-text-body-font-weight: 400;
  --g-text-caption-font-weight: 400;
  --g-text-header-font-weight: 600;
  --g-text-subheader-font-weight: 600;
  --g-text-display-font-weight: 600;
  --g-text-code-font-weight: 400;
  --g-text-accent-font-weight: 600;
  --g-text-body-1-font-size: 13px;
  --g-text-body-1-line-height: 18px;
  --g-text-body-2-font-size: 15px;
  --g-text-body-2-line-height: 20px;
  --g-text-body-3-font-size: 17px;
  --g-text-body-3-line-height: 24px;
  --g-text-body-short-font-size: 13px;
  --g-text-body-short-line-height: 16px;
  --g-text-caption-1-font-size: 9px;
  --g-text-caption-1-line-height: 12px;
  --g-text-caption-2-font-size: 11px;
  --g-text-caption-2-line-height: 16px;
  --g-text-header-1-font-size: 20px;
  --g-text-header-1-line-height: 24px;
  --g-text-header-2-font-size: 24px;
  --g-text-header-2-line-height: 28px;
  --g-text-subheader-1-font-size: 13px;
  --g-text-subheader-1-line-height: 18px;
  --g-text-subheader-2-font-size: 15px;
  --g-text-subheader-2-line-height: 20px;
  --g-text-subheader-3-font-size: 17px;
  --g-text-subheader-3-line-height: 24px;
  --g-text-display-1-font-size: 28px;
  --g-text-display-1-line-height: 36px;
  --g-text-display-2-font-size: 32px;
  --g-text-display-2-line-height: 40px;
  --g-text-display-3-font-size: 40px;
  --g-text-display-3-line-height: 48px;
  --g-text-display-4-font-size: 48px;
  --g-text-display-4-line-height: 52px;
  --g-text-code-1-font-size: 12px;
  --g-text-code-1-line-height: 18px;
  --g-text-code-2-font-size: 14px;
  --g-text-code-2-line-height: 20px;
  --g-text-code-3-font-size: 16px;
  --g-text-code-3-line-height: 24px;
  --g-text-code-inline-1-font-size: 12px;
  --g-text-code-inline-1-line-height: 14px;
  --g-text-code-inline-2-font-size: 14px;
  --g-text-code-inline-2-line-height: 16px;
  --g-text-code-inline-3-font-size: 16px;
  --g-text-code-inline-3-line-height: 20px;
  font-family: var(--g-text-body-font-family);
  font-weight: var(--g-text-body-font-weight);
  font-size: var(--g-text-body-1-font-size);
  line-height: var(--g-text-body-1-line-height);
  --g-spacing-base: 4px;
  --g-spacing-0: calc(var(--g-spacing-base) * 0);
  --g-spacing-half: calc(var(--g-spacing-base) * 0.5);
  --g-spacing-1: var(--g-spacing-base);
  --g-spacing-2: calc(var(--g-spacing-base) * 2);
  --g-spacing-3: calc(var(--g-spacing-base) * 3);
  --g-spacing-4: calc(var(--g-spacing-base) * 4);
  --g-spacing-5: calc(var(--g-spacing-base) * 5);
  --g-spacing-6: calc(var(--g-spacing-base) * 6);
  --g-spacing-7: calc(var(--g-spacing-base) * 7);
  --g-spacing-8: calc(var(--g-spacing-base) * 8);
  --g-spacing-9: calc(var(--g-spacing-base) * 9);
  --g-spacing-10: calc(var(--g-spacing-base) * 10);
  --g-scrollbar-width: 12px;
  --g-border-radius-xs: 3px;
  --g-border-radius-s: 5px;
  --g-border-radius-m: 6px;
  --g-border-radius-l: 8px;
  --g-border-radius-xl: 10px;
  --g-focus-border-radius: 2px;
  --g-font-family-sans: "Inter", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  --g-font-family-monospace: "Roboto Mono", "Menlo", "Monaco", "Consolas", "Ubuntu Mono", "Liberation Mono", "DejaVu Sans Mono", "Courier New", "Courier", monospace;
}
body.g-root {
  --g-flow-direction: 1;
  --g-flow-is-ltr: 1;
  --g-flow-is-rtl: 0;
}

.g-root[dir=ltr] {
  --g-flow-direction: 1;
  --g-flow-is-ltr: 1;
  --g-flow-is-rtl: 0;
}
.g-root[dir=rtl] {
  --g-flow-direction: -1;
  --g-flow-is-ltr: 0;
  --g-flow-is-rtl: 1;
}
.g-root_theme_light {
  --g-color-private-white-50: rgba(255, 255, 255, 0.05);
  --g-color-private-white-70: rgba(255, 255, 255, 0.07);
  --g-color-private-white-100: rgba(255, 255, 255, 0.1);
  --g-color-private-white-150: rgba(255, 255, 255, 0.15);
  --g-color-private-white-200: rgba(255, 255, 255, 0.2);
  --g-color-private-white-250: rgba(255, 255, 255, 0.25);
  --g-color-private-white-300: rgba(255, 255, 255, 0.3);
  --g-color-private-white-350: rgba(255, 255, 255, 0.35);
  --g-color-private-white-400: rgba(255, 255, 255, 0.4);
  --g-color-private-white-450: rgba(255, 255, 255, 0.45);
  --g-color-private-white-500: rgba(255, 255, 255, 0.5);
  --g-color-private-white-550: rgba(255, 255, 255, 0.55);
  --g-color-private-white-600: rgba(255, 255, 255, 0.6);
  --g-color-private-white-650: rgba(255, 255, 255, 0.65);
  --g-color-private-white-700: rgba(255, 255, 255, 0.7);
  --g-color-private-white-750: rgba(255, 255, 255, 0.75);
  --g-color-private-white-800: rgba(255, 255, 255, 0.8);
  --g-color-private-white-850: rgba(255, 255, 255, 0.85);
  --g-color-private-white-900: rgba(255, 255, 255, 0.9);
  --g-color-private-white-950: rgba(255, 255, 255, 0.95);
  --g-color-private-white-1000-solid: rgb(255, 255, 255);
  --g-color-private-black-50: rgba(0, 0, 0, 0.05);
  --g-color-private-black-70: rgba(0, 0, 0, 0.07);
  --g-color-private-black-100: rgba(0, 0, 0, 0.1);
  --g-color-private-black-150: rgba(0, 0, 0, 0.15);
  --g-color-private-black-200: rgba(0, 0, 0, 0.2);
  --g-color-private-black-250: rgba(0, 0, 0, 0.25);
  --g-color-private-black-300: rgba(0, 0, 0, 0.3);
  --g-color-private-black-350: rgba(0, 0, 0, 0.35);
  --g-color-private-black-400: rgba(0, 0, 0, 0.4);
  --g-color-private-black-450: rgba(0, 0, 0, 0.45);
  --g-color-private-black-500: rgba(0, 0, 0, 0.5);
  --g-color-private-black-550: rgba(0, 0, 0, 0.55);
  --g-color-private-black-600: rgba(0, 0, 0, 0.6);
  --g-color-private-black-650: rgba(0, 0, 0, 0.65);
  --g-color-private-black-700: rgba(0, 0, 0, 0.7);
  --g-color-private-black-750: rgba(0, 0, 0, 0.75);
  --g-color-private-black-800: rgba(0, 0, 0, 0.8);
  --g-color-private-black-850: rgba(0, 0, 0, 0.85);
  --g-color-private-black-900: rgba(0, 0, 0, 0.9);
  --g-color-private-black-950: rgba(0, 0, 0, 0.95);
  --g-color-private-black-20-solid: rgb(250, 250, 250);
  --g-color-private-black-50-solid: rgb(242, 242, 242);
  --g-color-private-black-100-solid: rgb(229, 229, 229);
  --g-color-private-black-150-solid: rgb(217, 217, 217);
  --g-color-private-black-200-solid: rgb(204, 204, 204);
  --g-color-private-black-250-solid: rgb(191, 191, 191);
  --g-color-private-black-300-solid: rgb(179, 179, 179);
  --g-color-private-black-350-solid: rgb(166, 166, 166);
  --g-color-private-black-400-solid: rgb(153, 153, 153);
  --g-color-private-black-450-solid: rgb(140, 140, 140);
  --g-color-private-black-500-solid: rgb(128, 128, 128);
  --g-color-private-black-550-solid: rgb(115, 115, 115);
  --g-color-private-black-600-solid: rgb(102, 102, 102);
  --g-color-private-black-650-solid: rgb(89, 89, 89);
  --g-color-private-black-700-solid: rgb(76, 76, 76);
  --g-color-private-black-750-solid: rgb(64, 64, 64);
  --g-color-private-black-800-solid: rgb(51, 51, 51);
  --g-color-private-black-850-solid: rgb(38, 38, 38);
  --g-color-private-black-900-solid: rgb(26, 26, 26);
  --g-color-private-black-950-solid: rgb(13, 13, 13);
  --g-color-private-black-1000-solid: rgb(0, 0, 0);
  --g-color-private-blue-50: rgba(54, 151, 241, 0.1);
  --g-color-private-blue-100: rgba(54, 151, 241, 0.15);
  --g-color-private-blue-150: rgba(54, 151, 241, 0.2);
  --g-color-private-blue-200: rgba(54, 151, 241, 0.3);
  --g-color-private-blue-250: rgba(54, 151, 241, 0.4);
  --g-color-private-blue-300: rgba(54, 151, 241, 0.5);
  --g-color-private-blue-350: rgba(54, 151, 241, 0.6);
  --g-color-private-blue-400: rgba(54, 151, 241, 0.7);
  --g-color-private-blue-450: rgba(54, 151, 241, 0.8);
  --g-color-private-blue-500: rgba(54, 151, 241, 0.9);
  --g-color-private-blue-50-solid: rgb(235, 245, 254);
  --g-color-private-blue-100-solid: rgb(225, 239, 253);
  --g-color-private-blue-150-solid: rgb(215, 234, 252);
  --g-color-private-blue-200-solid: rgb(195, 224, 251);
  --g-color-private-blue-250-solid: rgb(175, 213, 249);
  --g-color-private-blue-300-solid: rgb(155, 203, 248);
  --g-color-private-blue-350-solid: rgb(134, 193, 247);
  --g-color-private-blue-400-solid: rgb(114, 182, 245);
  --g-color-private-blue-450-solid: rgb(94, 172, 244);
  --g-color-private-blue-500-solid: rgb(74, 161, 242);
  --g-color-private-blue-550-solid: rgb(54, 151, 241);
  --g-color-private-blue-600-solid: rgb(52, 139, 220);
  --g-color-private-blue-650-solid: rgb(50, 127, 200);
  --g-color-private-blue-700-solid: rgb(48, 114, 179);
  --g-color-private-blue-750-solid: rgb(46, 102, 158);
  --g-color-private-blue-800-solid: rgb(44, 90, 138);
  --g-color-private-blue-850-solid: rgb(42, 78, 117);
  --g-color-private-blue-900-solid: rgb(40, 66, 96);
  --g-color-private-blue-950-solid: rgb(38, 53, 75);
  --g-color-private-blue-1000-solid: rgb(37, 47, 65);
  --g-color-private-green-50: rgba(50, 186, 118, 0.1);
  --g-color-private-green-100: rgba(50, 186, 118, 0.15);
  --g-color-private-green-150: rgba(50, 186, 118, 0.2);
  --g-color-private-green-200: rgba(50, 186, 118, 0.3);
  --g-color-private-green-250: rgba(50, 186, 118, 0.4);
  --g-color-private-green-300: rgba(50, 186, 118, 0.5);
  --g-color-private-green-350: rgba(50, 186, 118, 0.6);
  --g-color-private-green-400: rgba(50, 186, 118, 0.7);
  --g-color-private-green-450: rgba(50, 186, 118, 0.8);
  --g-color-private-green-500: rgba(50, 186, 118, 0.9);
  --g-color-private-green-50-solid: rgb(235, 248, 241);
  --g-color-private-green-100-solid: rgb(224, 245, 234);
  --g-color-private-green-150-solid: rgb(214, 241, 228);
  --g-color-private-green-200-solid: rgb(194, 234, 214);
  --g-color-private-green-250-solid: rgb(173, 227, 200);
  --g-color-private-green-300-solid: rgb(153, 221, 187);
  --g-color-private-green-350-solid: rgb(132, 214, 173);
  --g-color-private-green-400-solid: rgb(112, 207, 159);
  --g-color-private-green-450-solid: rgb(91, 200, 145);
  --g-color-private-green-500-solid: rgb(71, 193, 132);
  --g-color-private-green-550-solid: rgb(50, 186, 118);
  --g-color-private-green-600-solid: rgb(48, 170, 110);
  --g-color-private-green-650-solid: rgb(47, 155, 101);
  --g-color-private-green-700-solid: rgb(45, 139, 93);
  --g-color-private-green-750-solid: rgb(44, 123, 84);
  --g-color-private-green-800-solid: rgb(42, 108, 76);
  --g-color-private-green-850-solid: rgb(40, 92, 68);
  --g-color-private-green-900-solid: rgb(39, 76, 59);
  --g-color-private-green-950-solid: rgb(37, 60, 51);
  --g-color-private-green-1000-solid: rgb(36, 53, 47);
  --g-color-private-yellow-50: rgba(255, 190, 92, 0.1);
  --g-color-private-yellow-100: rgba(255, 190, 92, 0.15);
  --g-color-private-yellow-150: rgba(255, 190, 92, 0.2);
  --g-color-private-yellow-200: rgba(255, 190, 92, 0.3);
  --g-color-private-yellow-250: rgba(255, 190, 92, 0.4);
  --g-color-private-yellow-300: rgba(255, 190, 92, 0.5);
  --g-color-private-yellow-350: rgba(255, 190, 92, 0.6);
  --g-color-private-yellow-400: rgba(255, 190, 92, 0.7);
  --g-color-private-yellow-450: rgba(255, 190, 92, 0.8);
  --g-color-private-yellow-500: rgba(255, 190, 92, 0.9);
  --g-color-private-yellow-50-solid: rgb(255, 249, 239);
  --g-color-private-yellow-100-solid: rgb(255, 245, 231);
  --g-color-private-yellow-150-solid: rgb(255, 242, 222);
  --g-color-private-yellow-200-solid: rgb(255, 236, 206);
  --g-color-private-yellow-250-solid: rgb(255, 229, 190);
  --g-color-private-yellow-300-solid: rgb(255, 223, 174);
  --g-color-private-yellow-350-solid: rgb(255, 216, 157);
  --g-color-private-yellow-400-solid: rgb(255, 210, 141);
  --g-color-private-yellow-450-solid: rgb(255, 203, 125);
  --g-color-private-yellow-500-solid: rgb(255, 197, 108);
  --g-color-private-yellow-550-solid: rgb(255, 190, 92);
  --g-color-private-yellow-600-solid: rgb(233, 174, 86);
  --g-color-private-yellow-650-solid: rgb(211, 158, 80);
  --g-color-private-yellow-700-solid: rgb(189, 142, 75);
  --g-color-private-yellow-750-solid: rgb(167, 126, 69);
  --g-color-private-yellow-800-solid: rgb(145, 110, 63);
  --g-color-private-yellow-850-solid: rgb(122, 93, 57);
  --g-color-private-yellow-900-solid: rgb(100, 77, 51);
  --g-color-private-yellow-950-solid: rgb(78, 61, 46);
  --g-color-private-yellow-1000-solid: rgb(67, 53, 43);
  --g-color-private-orange-50: rgba(255, 119, 0, 0.1);
  --g-color-private-orange-100: rgba(255, 119, 0, 0.15);
  --g-color-private-orange-150: rgba(255, 119, 0, 0.2);
  --g-color-private-orange-200: rgba(255, 119, 0, 0.3);
  --g-color-private-orange-250: rgba(255, 119, 0, 0.4);
  --g-color-private-orange-300: rgba(255, 119, 0, 0.5);
  --g-color-private-orange-350: rgba(255, 119, 0, 0.6);
  --g-color-private-orange-400: rgba(255, 119, 0, 0.7);
  --g-color-private-orange-450: rgba(255, 119, 0, 0.8);
  --g-color-private-orange-500: rgba(255, 119, 0, 0.9);
  --g-color-private-orange-50-solid: rgb(255, 241, 230);
  --g-color-private-orange-100-solid: rgb(255, 235, 217);
  --g-color-private-orange-150-solid: rgb(255, 228, 204);
  --g-color-private-orange-200-solid: rgb(255, 214, 179);
  --g-color-private-orange-250-solid: rgb(255, 201, 153);
  --g-color-private-orange-300-solid: rgb(255, 187, 128);
  --g-color-private-orange-350-solid: rgb(255, 173, 102);
  --g-color-private-orange-400-solid: rgb(255, 160, 77);
  --g-color-private-orange-450-solid: rgb(255, 146, 51);
  --g-color-private-orange-500-solid: rgb(255, 133, 25);
  --g-color-private-orange-550-solid: rgb(255, 119, 0);
  --g-color-private-orange-600-solid: rgb(233, 110, 3);
  --g-color-private-orange-650-solid: rgb(211, 101, 7);
  --g-color-private-orange-700-solid: rgb(189, 92, 10);
  --g-color-private-orange-750-solid: rgb(167, 83, 14);
  --g-color-private-orange-800-solid: rgb(145, 74, 17);
  --g-color-private-orange-850-solid: rgb(122, 65, 20);
  --g-color-private-orange-900-solid: rgb(100, 56, 24);
  --g-color-private-orange-950-solid: rgb(78, 47, 27);
  --g-color-private-orange-1000-solid: rgb(67, 43, 29);
  --g-color-private-red-50: rgba(255, 0, 61, 0.1);
  --g-color-private-red-100: rgba(255, 0, 61, 0.15);
  --g-color-private-red-150: rgba(255, 0, 61, 0.2);
  --g-color-private-red-200: rgba(255, 0, 61, 0.3);
  --g-color-private-red-250: rgba(255, 0, 61, 0.4);
  --g-color-private-red-300: rgba(255, 0, 61, 0.5);
  --g-color-private-red-350: rgba(255, 0, 61, 0.6);
  --g-color-private-red-400: rgba(255, 0, 61, 0.7);
  --g-color-private-red-450: rgba(255, 0, 61, 0.8);
  --g-color-private-red-500: rgba(255, 0, 61, 0.9);
  --g-color-private-red-50-solid: rgb(255, 230, 236);
  --g-color-private-red-100-solid: rgb(255, 217, 226);
  --g-color-private-red-150-solid: rgb(255, 204, 216);
  --g-color-private-red-200-solid: rgb(255, 179, 197);
  --g-color-private-red-250-solid: rgb(255, 153, 177);
  --g-color-private-red-300-solid: rgb(255, 128, 158);
  --g-color-private-red-350-solid: rgb(255, 102, 139);
  --g-color-private-red-400-solid: rgb(255, 77, 119);
  --g-color-private-red-450-solid: rgb(255, 51, 100);
  --g-color-private-red-500-solid: rgb(255, 25, 80);
  --g-color-private-red-550-solid: rgb(255, 0, 61);
  --g-color-private-red-600-solid: rgb(233, 3, 58);
  --g-color-private-red-650-solid: rgb(211, 6, 56);
  --g-color-private-red-700-solid: rgb(189, 9, 53);
  --g-color-private-red-750-solid: rgb(167, 12, 50);
  --g-color-private-red-800-solid: rgb(145, 15, 48);
  --g-color-private-red-850-solid: rgb(122, 17, 45);
  --g-color-private-red-900-solid: rgb(100, 20, 42);
  --g-color-private-red-950-solid: rgb(78, 23, 39);
  --g-color-private-red-1000-solid: rgb(67, 25, 38);
  --g-color-private-purple-50: rgba(143, 82, 204, 0.1);
  --g-color-private-purple-100: rgba(143, 82, 204, 0.15);
  --g-color-private-purple-150: rgba(143, 82, 204, 0.2);
  --g-color-private-purple-200: rgba(143, 82, 204, 0.3);
  --g-color-private-purple-250: rgba(143, 82, 204, 0.4);
  --g-color-private-purple-300: rgba(143, 82, 204, 0.5);
  --g-color-private-purple-350: rgba(143, 82, 204, 0.6);
  --g-color-private-purple-400: rgba(143, 82, 204, 0.7);
  --g-color-private-purple-450: rgba(143, 82, 204, 0.8);
  --g-color-private-purple-500: rgba(143, 82, 204, 0.9);
  --g-color-private-purple-50-solid: rgb(244, 238, 250);
  --g-color-private-purple-100-solid: rgb(238, 229, 247);
  --g-color-private-purple-150-solid: rgb(233, 220, 245);
  --g-color-private-purple-200-solid: rgb(221, 203, 240);
  --g-color-private-purple-250-solid: rgb(210, 186, 235);
  --g-color-private-purple-300-solid: rgb(199, 169, 230);
  --g-color-private-purple-350-solid: rgb(188, 151, 224);
  --g-color-private-purple-400-solid: rgb(177, 134, 219);
  --g-color-private-purple-450-solid: rgb(165, 117, 214);
  --g-color-private-purple-500-solid: rgb(154, 99, 209);
  --g-color-private-purple-550-solid: rgb(143, 82, 204);
  --g-color-private-purple-600-solid: rgb(132, 77, 187);
  --g-color-private-purple-650-solid: rgb(121, 71, 170);
  --g-color-private-purple-700-solid: rgb(110, 66, 153);
  --g-color-private-purple-750-solid: rgb(99, 61, 136);
  --g-color-private-purple-800-solid: rgb(89, 56, 119);
  --g-color-private-purple-850-solid: rgb(78, 50, 102);
  --g-color-private-purple-900-solid: rgb(67, 45, 85);
  --g-color-private-purple-950-solid: rgb(56, 40, 68);
  --g-color-private-purple-1000-solid: rgb(50, 37, 60);
  --g-color-private-cool-grey-50: rgba(107, 132, 153, 0.1);
  --g-color-private-cool-grey-100: rgba(107, 132, 153, 0.15);
  --g-color-private-cool-grey-150: rgba(107, 132, 153, 0.2);
  --g-color-private-cool-grey-200: rgba(107, 132, 153, 0.3);
  --g-color-private-cool-grey-250: rgba(107, 132, 153, 0.4);
  --g-color-private-cool-grey-300: rgba(107, 132, 153, 0.5);
  --g-color-private-cool-grey-350: rgba(107, 132, 153, 0.6);
  --g-color-private-cool-grey-400: rgba(107, 132, 153, 0.7);
  --g-color-private-cool-grey-450: rgba(107, 132, 153, 0.8);
  --g-color-private-cool-grey-500: rgba(107, 132, 153, 0.9);
  --g-color-private-cool-grey-50-solid: rgb(240, 243, 245);
  --g-color-private-cool-grey-100-solid: rgb(233, 237, 240);
  --g-color-private-cool-grey-150-solid: rgb(225, 230, 235);
  --g-color-private-cool-grey-200-solid: rgb(211, 218, 224);
  --g-color-private-cool-grey-250-solid: rgb(196, 206, 214);
  --g-color-private-cool-grey-300-solid: rgb(181, 194, 204);
  --g-color-private-cool-grey-350-solid: rgb(166, 181, 194);
  --g-color-private-cool-grey-400-solid: rgb(151, 169, 184);
  --g-color-private-cool-grey-450-solid: rgb(137, 157, 173);
  --g-color-private-cool-grey-500-solid: rgb(122, 144, 163);
  --g-color-private-cool-grey-550-solid: rgb(107, 132, 153);
  --g-color-private-cool-grey-600-solid: rgb(100, 122, 141);
  --g-color-private-cool-grey-650-solid: rgb(92, 111, 129);
  --g-color-private-cool-grey-700-solid: rgb(85, 101, 117);
  --g-color-private-cool-grey-750-solid: rgb(78, 91, 105);
  --g-color-private-cool-grey-800-solid: rgb(71, 81, 94);
  --g-color-private-cool-grey-850-solid: rgb(63, 70, 82);
  --g-color-private-cool-grey-900-solid: rgb(56, 60, 70);
  --g-color-private-cool-grey-950-solid: rgb(49, 50, 58);
  --g-color-private-cool-grey-1000-solid: rgb(45, 44, 52);
  --g-color-text-primary: var(--g-color-text-dark-primary);
  --g-color-text-complementary: var(--g-color-text-dark-complementary);
  --g-color-text-secondary: var(--g-color-text-dark-secondary);
  --g-color-text-hint: var(--g-color-text-dark-hint);
  --g-color-text-info: var(--g-color-private-blue-600-solid);
  --g-color-text-positive: var(--g-color-private-green-600-solid);
  --g-color-text-warning: var(--g-color-private-yellow-700-solid);
  --g-color-text-danger: var(--g-color-private-red-600-solid);
  --g-color-text-utility: var(--g-color-private-purple-600-solid);
  --g-color-text-misc: var(--g-color-private-cool-grey-600-solid);
  --g-color-text-info-heavy: var(--g-color-private-blue-700-solid);
  --g-color-text-positive-heavy: var(--g-color-private-green-700-solid);
  --g-color-text-warning-heavy: var(--g-color-private-orange-700-solid);
  --g-color-text-danger-heavy: var(--g-color-private-red-700-solid);
  --g-color-text-utility-heavy: var(--g-color-private-purple-700-solid);
  --g-color-text-misc-heavy: var(--g-color-private-cool-grey-700-solid);
  --g-color-text-brand: var(--g-color-private-yellow-700-solid);
  --g-color-text-brand-heavy: var(--g-color-private-orange-700-solid);
  --g-color-text-brand-contrast: var(--g-color-text-dark-primary);
  --g-color-text-link: var(--g-color-private-yellow-650-solid);
  --g-color-text-link-hover: var(--g-color-private-orange-650-solid);
  --g-color-text-link-visited: var(--g-color-private-purple-550-solid);
  --g-color-text-link-visited-hover: var(--g-color-private-purple-800-solid);
  --g-color-text-dark-primary: var(--g-color-private-black-850);
  --g-color-text-dark-complementary: var(--g-color-private-black-700);
  --g-color-text-dark-secondary: var(--g-color-private-black-500);
  --g-color-text-dark-hint: var(--g-color-private-black-300);
  --g-color-text-light-primary: var(--g-color-private-white-1000-solid);
  --g-color-text-light-complementary: var(--g-color-private-white-850);
  --g-color-text-light-secondary: var(--g-color-private-white-700);
  --g-color-text-light-hint: var(--g-color-private-white-500);
  --g-color-text-inverted-primary: var(--g-color-text-light-primary);
  --g-color-text-inverted-complementary: var(--g-color-text-light-complementary);
  --g-color-text-inverted-secondary: var(--g-color-text-light-secondary);
  --g-color-text-inverted-hint: var(--g-color-text-light-hint);
  --g-color-base-background: var(--g-color-private-white-1000-solid);
  --g-color-base-generic: var(--g-color-private-black-50);
  --g-color-base-generic-hover: var(--g-color-private-black-150);
  --g-color-base-generic-medium: var(--g-color-private-black-150);
  --g-color-base-generic-medium-hover: var(--g-color-private-black-250);
  --g-color-base-generic-accent: var(--g-color-private-black-150);
  --g-color-base-generic-accent-disabled: var(--g-color-private-black-70);
  --g-color-base-generic-ultralight: var(--g-color-private-black-20-solid);
  --g-color-base-simple-hover: var(--g-color-private-black-50);
  --g-color-base-simple-hover-solid: var(--g-color-private-black-50-solid);
  --g-color-base-brand: var(--g-color-private-yellow-550-solid);
  --g-color-base-brand-hover: var(--g-color-private-yellow-600-solid);
  --g-color-base-selection: var(--g-color-private-yellow-200);
  --g-color-base-selection-hover: var(--g-color-private-yellow-300);
  --g-color-base-info-light: var(--g-color-private-blue-100);
  --g-color-base-info-light-hover: var(--g-color-private-blue-200);
  --g-color-base-info-medium: var(--g-color-private-blue-200);
  --g-color-base-info-medium-hover: var(--g-color-private-blue-300);
  --g-color-base-info-heavy: var(--g-color-private-blue-600-solid);
  --g-color-base-info-heavy-hover: var(--g-color-private-blue-700-solid);
  --g-color-base-positive-light: var(--g-color-private-green-100);
  --g-color-base-positive-light-hover: var(--g-color-private-green-200);
  --g-color-base-positive-medium: var(--g-color-private-green-200);
  --g-color-base-positive-medium-hover: var(--g-color-private-green-300);
  --g-color-base-positive-heavy: var(--g-color-private-green-600-solid);
  --g-color-base-positive-heavy-hover: var(--g-color-private-green-700-solid);
  --g-color-base-warning-light: var(--g-color-private-yellow-200);
  --g-color-base-warning-light-hover: var(--g-color-private-yellow-300);
  --g-color-base-warning-medium: var(--g-color-private-yellow-400);
  --g-color-base-warning-medium-hover: var(--g-color-private-yellow-500);
  --g-color-base-warning-heavy: var(--g-color-private-yellow-550-solid);
  --g-color-base-warning-heavy-hover: var(--g-color-private-yellow-650-solid);
  --g-color-base-danger-light: var(--g-color-private-red-100);
  --g-color-base-danger-light-hover: var(--g-color-private-red-200);
  --g-color-base-danger-medium: var(--g-color-private-red-200);
  --g-color-base-danger-medium-hover: var(--g-color-private-red-300);
  --g-color-base-danger-heavy: var(--g-color-private-red-600-solid);
  --g-color-base-danger-heavy-hover: var(--g-color-private-red-700-solid);
  --g-color-base-utility-light: var(--g-color-private-purple-100);
  --g-color-base-utility-light-hover: var(--g-color-private-purple-200);
  --g-color-base-utility-medium: var(--g-color-private-purple-200);
  --g-color-base-utility-medium-hover: var(--g-color-private-purple-300);
  --g-color-base-utility-heavy: var(--g-color-private-purple-600-solid);
  --g-color-base-utility-heavy-hover: var(--g-color-private-purple-700-solid);
  --g-color-base-neutral-light: var(--g-color-private-black-50);
  --g-color-base-neutral-light-hover: var(--g-color-private-black-100);
  --g-color-base-neutral-medium: var(--g-color-private-black-200);
  --g-color-base-neutral-medium-hover: var(--g-color-private-black-250);
  --g-color-base-neutral-heavy: var(--g-color-private-black-450);
  --g-color-base-neutral-heavy-hover: var(--g-color-private-black-550);
  --g-color-base-misc-light: var(--g-color-private-cool-grey-100);
  --g-color-base-misc-light-hover: var(--g-color-private-cool-grey-200);
  --g-color-base-misc-medium: var(--g-color-private-cool-grey-200);
  --g-color-base-misc-medium-hover: var(--g-color-private-cool-grey-300);
  --g-color-base-misc-heavy: var(--g-color-private-cool-grey-600-solid);
  --g-color-base-misc-heavy-hover: var(--g-color-private-cool-grey-700-solid);
  --g-color-base-light: var(--g-color-private-white-1000-solid);
  --g-color-base-light-hover: var(--g-color-private-white-850);
  --g-color-base-light-simple-hover: var(--g-color-private-white-150);
  --g-color-base-light-disabled: var(--g-color-private-white-150);
  --g-color-base-light-accent-disabled: var(--g-color-private-white-300);
  --g-color-base-float: var(--g-color-private-white-1000-solid);
  --g-color-base-float-hover: var(--g-color-private-black-50-solid);
  --g-color-base-float-medium: var(--g-color-private-black-550-solid);
  --g-color-base-float-heavy: var(--g-color-private-black-700-solid);
  --g-color-base-float-accent: var(--g-color-private-white-1000-solid);
  --g-color-base-float-accent-hover: var(--g-color-private-white-850);
  --g-color-base-float-announcement: var(--g-color-private-cool-grey-50-solid);
  --g-color-base-modal: var(--g-color-base-background);
  --g-color-line-generic: var(--g-color-private-black-100);
  --g-color-line-generic-hover: var(--g-color-private-black-150);
  --g-color-line-generic-active: var(--g-color-private-black-300);
  --g-color-line-generic-accent: var(--g-color-private-black-150);
  --g-color-line-generic-accent-hover: var(--g-color-private-black-300);
  --g-color-line-generic-solid: var(--g-color-private-black-100-solid);
  --g-color-line-brand: var(--g-color-private-yellow-600-solid);
  --g-color-line-focus: var(--g-color-private-cool-grey-450);
  --g-color-line-light: var(--g-color-private-white-500);
  --g-color-line-info: var(--g-color-private-blue-450);
  --g-color-line-positive: var(--g-color-private-green-450);
  --g-color-line-warning: var(--g-color-private-yellow-600-solid);
  --g-color-line-danger: var(--g-color-private-red-450);
  --g-color-line-utility: var(--g-color-private-purple-450);
  --g-color-line-misc: var(--g-color-private-cool-grey-450);
  --g-color-sfx-veil: var(--g-color-private-black-250);
  --g-color-sfx-shadow: var(--g-color-private-black-150);
  --g-color-sfx-shadow-heavy: var(--g-color-private-black-500);
  --g-color-sfx-shadow-light: var(--g-color-private-black-50);
  --g-color-sfx-fade: var(--g-color-private-white-300);
  --g-color-scroll-track: var(--g-color-base-background);
  --g-color-scroll-handle: var(--g-color-private-black-100);
  --g-color-scroll-handle-hover: var(--g-color-private-black-150);
  --g-color-scroll-corner: var(--g-color-private-black-100);
  --g-color-infographics-axis: var(--g-color-private-black-150-solid);
  --g-color-infographics-tooltip-bg: var(--g-color-private-white-950);
  --gn-aside-header-decoration-expanded-background-color: #ffffff63;
  --gn-aside-header-decoration-collapsed-background-color: var(--g-color-private-brand-300);
  --gn-aside-header-item-current-background-color: var(--g-color-private-brand-200);
  --g-color-private-brand-50: rgba(108,145,201,0.1);
  --g-color-private-brand-100: rgba(108,145,201,0.15);
  --g-color-private-brand-150: rgba(108,145,201,0.2);
  --g-color-private-brand-200: rgba(108,145,201,0.3);
  --g-color-private-brand-250: rgba(108,145,201,0.4);
  --g-color-private-brand-300: rgba(108,145,201,0.5);
  --g-color-private-brand-350: rgba(108,145,201,0.6);
  --g-color-private-brand-400: rgba(108,145,201,0.7);
  --g-color-private-brand-450: rgba(108,145,201,0.8);
  --g-color-private-brand-500: rgba(108,145,201,0.9);
  --g-color-private-brand-550-solid: rgb(108,145,201);
  --g-color-private-brand-1000-solid: rgb(45,46,59);
  --g-color-private-brand-950-solid: rgb(49,52,67);
  --g-color-private-brand-900-solid: rgb(56,64,84);
  --g-color-private-brand-850-solid: rgb(64,75,101);
  --g-color-private-brand-800-solid: rgb(71,87,118);
  --g-color-private-brand-750-solid: rgb(78,99,134);
  --g-color-private-brand-700-solid: rgb(86,110,151);
  --g-color-private-brand-650-solid: rgb(93,122,168);
  --g-color-private-brand-600-solid: rgb(101,133,184);
  --g-color-private-brand-500-solid: rgb(123,156,206);
  --g-color-private-brand-450-solid: rgb(137,167,212);
  --g-color-private-brand-400-solid: rgb(152,178,217);
  --g-color-private-brand-350-solid: rgb(167,189,223);
  --g-color-private-brand-300-solid: rgb(182,200,228);
  --g-color-private-brand-250-solid: rgb(196,211,233);
  --g-color-private-brand-200-solid: rgb(211,222,239);
  --g-color-private-brand-150-solid: rgb(226,233,244);
  --g-color-private-brand-100-solid: rgb(233,239,247);
  --g-color-private-brand-50-solid: rgb(240,244,250);
  --g-color-base-brand: rgb(108,145,201);
  --g-color-base-background: rgb(255,255,255);
  --g-color-base-brand-hover: var(--g-color-private-brand-600-solid);
  --g-color-base-selection: var(--g-color-private-brand-200);
  --g-color-base-selection-hover: var(--g-color-private-brand-300);
  --g-color-line-brand: var(--g-color-private-brand-600-solid);
  --g-color-text-brand: var(--g-color-private-brand-700-solid);
  --g-color-text-brand-heavy: var(--g-color-private-brand-700-solid);
  --g-color-text-brand-contrast: rgb(255, 255, 255);
  --g-color-text-link: var(--g-color-private-brand-600-solid);
  --g-color-text-link-hover: var(--g-color-private-orange-800-solid);
  --g-color-text-link-visited: var(--g-color-private-purple-550-solid);
  --g-color-text-link-visited-hover: var(--g-color-private-purple-800-solid);
}
.g-root_theme_dark {
  --g-color-private-white-50: rgba(255, 255, 255, 0.05);
  --g-color-private-white-70: rgba(255, 255, 255, 0.07);
  --g-color-private-white-100: rgba(255, 255, 255, 0.1);
  --g-color-private-white-150: rgba(255, 255, 255, 0.15);
  --g-color-private-white-200: rgba(255, 255, 255, 0.2);
  --g-color-private-white-250: rgba(255, 255, 255, 0.25);
  --g-color-private-white-300: rgba(255, 255, 255, 0.3);
  --g-color-private-white-350: rgba(255, 255, 255, 0.35);
  --g-color-private-white-400: rgba(255, 255, 255, 0.4);
  --g-color-private-white-450: rgba(255, 255, 255, 0.45);
  --g-color-private-white-500: rgba(255, 255, 255, 0.5);
  --g-color-private-white-550: rgba(255, 255, 255, 0.55);
  --g-color-private-white-600: rgba(255, 255, 255, 0.6);
  --g-color-private-white-650: rgba(255, 255, 255, 0.65);
  --g-color-private-white-700: rgba(255, 255, 255, 0.7);
  --g-color-private-white-750: rgba(255, 255, 255, 0.75);
  --g-color-private-white-800: rgba(255, 255, 255, 0.8);
  --g-color-private-white-850: rgba(255, 255, 255, 0.85);
  --g-color-private-white-900: rgba(255, 255, 255, 0.9);
  --g-color-private-white-950: rgba(255, 255, 255, 0.95);
  --g-color-private-white-20-solid: rgb(38, 34, 38);
  --g-color-private-white-50-solid: rgb(45, 40, 45);
  --g-color-private-white-70-solid: rgb(49, 45, 49);
  --g-color-private-white-100-solid: rgb(56, 52, 56);
  --g-color-private-white-150-solid: rgb(67, 63, 67);
  --g-color-private-white-200-solid: rgb(78, 74, 78);
  --g-color-private-white-250-solid: rgb(89, 85, 89);
  --g-color-private-white-300-solid: rgb(100, 97, 100);
  --g-color-private-white-350-solid: rgb(111, 108, 111);
  --g-color-private-white-400-solid: rgb(122, 119, 122);
  --g-color-private-white-450-solid: rgb(133, 131, 133);
  --g-color-private-white-500-solid: rgb(144, 142, 144);
  --g-color-private-white-550-solid: rgb(156, 153, 156);
  --g-color-private-white-600-solid: rgb(167, 165, 167);
  --g-color-private-white-650-solid: rgb(178, 176, 178);
  --g-color-private-white-700-solid: rgb(189, 187, 189);
  --g-color-private-white-750-solid: rgb(200, 198, 200);
  --g-color-private-white-800-solid: rgb(211, 210, 211);
  --g-color-private-white-850-solid: rgb(222, 221, 222);
  --g-color-private-white-900-solid: rgb(233, 232, 233);
  --g-color-private-white-950-solid: rgb(244, 244, 244);
  --g-color-private-white-1000-solid: rgb(255, 255, 255);
  --g-color-private-white-opaque-150: rgba(76, 75, 81, 0.95);
  --g-color-private-black-20: rgba(0, 0, 0, 0.02);
  --g-color-private-black-50: rgba(0, 0, 0, 0.05);
  --g-color-private-black-100: rgba(0, 0, 0, 0.1);
  --g-color-private-black-150: rgba(0, 0, 0, 0.15);
  --g-color-private-black-200: rgba(0, 0, 0, 0.2);
  --g-color-private-black-250: rgba(0, 0, 0, 0.25);
  --g-color-private-black-300: rgba(0, 0, 0, 0.3);
  --g-color-private-black-350: rgba(0, 0, 0, 0.35);
  --g-color-private-black-400: rgba(0, 0, 0, 0.4);
  --g-color-private-black-450: rgba(0, 0, 0, 0.45);
  --g-color-private-black-500: rgba(0, 0, 0, 0.5);
  --g-color-private-black-550: rgba(0, 0, 0, 0.55);
  --g-color-private-black-600: rgba(0, 0, 0, 0.6);
  --g-color-private-black-650: rgba(0, 0, 0, 0.65);
  --g-color-private-black-700: rgba(0, 0, 0, 0.7);
  --g-color-private-black-750: rgba(0, 0, 0, 0.75);
  --g-color-private-black-800: rgba(0, 0, 0, 0.8);
  --g-color-private-black-850: rgba(0, 0, 0, 0.85);
  --g-color-private-black-900: rgba(0, 0, 0, 0.9);
  --g-color-private-black-950: rgba(0, 0, 0, 0.95);
  --g-color-private-black-1000-solid: rgb(0, 0, 0);
  --g-color-private-blue-50: rgba(54, 151, 241, 0.1);
  --g-color-private-blue-100: rgba(54, 151, 241, 0.15);
  --g-color-private-blue-150: rgba(54, 151, 241, 0.2);
  --g-color-private-blue-200: rgba(54, 151, 241, 0.3);
  --g-color-private-blue-250: rgba(54, 151, 241, 0.4);
  --g-color-private-blue-300: rgba(54, 151, 241, 0.5);
  --g-color-private-blue-350: rgba(54, 151, 241, 0.6);
  --g-color-private-blue-400: rgba(54, 151, 241, 0.7);
  --g-color-private-blue-450: rgba(54, 151, 241, 0.8);
  --g-color-private-blue-500: rgba(54, 151, 241, 0.9);
  --g-color-private-blue-50-solid: rgb(36, 41, 55);
  --g-color-private-blue-100-solid: rgb(37, 47, 65);
  --g-color-private-blue-150-solid: rgb(38, 53, 75);
  --g-color-private-blue-200-solid: rgb(40, 66, 96);
  --g-color-private-blue-250-solid: rgb(42, 78, 117);
  --g-color-private-blue-300-solid: rgb(44, 90, 138);
  --g-color-private-blue-350-solid: rgb(46, 102, 158);
  --g-color-private-blue-400-solid: rgb(48, 114, 179);
  --g-color-private-blue-450-solid: rgb(50, 127, 200);
  --g-color-private-blue-500-solid: rgb(52, 139, 220);
  --g-color-private-blue-550-solid: rgb(54, 151, 241);
  --g-color-private-blue-600-solid: rgb(74, 161, 242);
  --g-color-private-blue-650-solid: rgb(94, 172, 244);
  --g-color-private-blue-700-solid: rgb(114, 182, 245);
  --g-color-private-blue-750-solid: rgb(134, 193, 247);
  --g-color-private-blue-800-solid: rgb(155, 203, 248);
  --g-color-private-blue-850-solid: rgb(175, 213, 249);
  --g-color-private-blue-900-solid: rgb(195, 224, 251);
  --g-color-private-blue-950-solid: rgb(215, 234, 252);
  --g-color-private-blue-1000-solid: rgb(225, 239, 253);
  --g-color-private-green-50: rgba(77, 176, 155, 0.1);
  --g-color-private-green-100: rgba(77, 176, 155, 0.15);
  --g-color-private-green-150: rgba(77, 176, 155, 0.2);
  --g-color-private-green-200: rgba(77, 176, 155, 0.3);
  --g-color-private-green-250: rgba(77, 176, 155, 0.4);
  --g-color-private-green-300: rgba(77, 176, 155, 0.5);
  --g-color-private-green-350: rgba(77, 176, 155, 0.6);
  --g-color-private-green-400: rgba(77, 176, 155, 0.7);
  --g-color-private-green-450: rgba(77, 176, 155, 0.8);
  --g-color-private-green-500: rgba(77, 176, 155, 0.9);
  --g-color-private-green-50-solid: rgb(38, 44, 46);
  --g-color-private-green-100-solid: rgb(40, 51, 52);
  --g-color-private-green-150-solid: rgb(43, 58, 58);
  --g-color-private-green-200-solid: rgb(47, 73, 70);
  --g-color-private-green-250-solid: rgb(51, 88, 82);
  --g-color-private-green-300-solid: rgb(56, 103, 95);
  --g-color-private-green-350-solid: rgb(60, 117, 107);
  --g-color-private-green-400-solid: rgb(64, 132, 119);
  --g-color-private-green-450-solid: rgb(68, 147, 131);
  --g-color-private-green-500-solid: rgb(73, 161, 143);
  --g-color-private-green-550-solid: rgb(77, 176, 155);
  --g-color-private-green-600-solid: rgb(95, 184, 165);
  --g-color-private-green-650-solid: rgb(113, 192, 175);
  --g-color-private-green-700-solid: rgb(130, 200, 185);
  --g-color-private-green-750-solid: rgb(148, 208, 195);
  --g-color-private-green-800-solid: rgb(166, 216, 205);
  --g-color-private-green-850-solid: rgb(184, 223, 215);
  --g-color-private-green-900-solid: rgb(202, 231, 225);
  --g-color-private-green-950-solid: rgb(219, 239, 235);
  --g-color-private-green-1000-solid: rgb(228, 243, 240);
  --g-color-private-yellow-50: rgba(255, 190, 92, 0.1);
  --g-color-private-yellow-100: rgba(255, 190, 92, 0.15);
  --g-color-private-yellow-150: rgba(255, 190, 92, 0.2);
  --g-color-private-yellow-200: rgba(255, 190, 92, 0.3);
  --g-color-private-yellow-250: rgba(255, 190, 92, 0.4);
  --g-color-private-yellow-300: rgba(255, 190, 92, 0.5);
  --g-color-private-yellow-350: rgba(255, 190, 92, 0.6);
  --g-color-private-yellow-400: rgba(255, 190, 92, 0.7);
  --g-color-private-yellow-450: rgba(255, 190, 92, 0.8);
  --g-color-private-yellow-500: rgba(255, 190, 92, 0.9);
  --g-color-private-yellow-50-solid: rgb(56, 45, 40);
  --g-color-private-yellow-100-solid: rgb(67, 53, 43);
  --g-color-private-yellow-150-solid: rgb(78, 61, 46);
  --g-color-private-yellow-200-solid: rgb(100, 77, 51);
  --g-color-private-yellow-250-solid: rgb(122, 93, 57);
  --g-color-private-yellow-300-solid: rgb(145, 110, 63);
  --g-color-private-yellow-350-solid: rgb(167, 126, 69);
  --g-color-private-yellow-400-solid: rgb(189, 142, 75);
  --g-color-private-yellow-450-solid: rgb(211, 158, 80);
  --g-color-private-yellow-500-solid: rgb(233, 174, 86);
  --g-color-private-yellow-550-solid: rgb(255, 190, 92);
  --g-color-private-yellow-600-solid: rgb(255, 197, 108);
  --g-color-private-yellow-650-solid: rgb(255, 203, 125);
  --g-color-private-yellow-700-solid: rgb(255, 210, 141);
  --g-color-private-yellow-750-solid: rgb(255, 216, 157);
  --g-color-private-yellow-800-solid: rgb(255, 223, 174);
  --g-color-private-yellow-850-solid: rgb(255, 229, 190);
  --g-color-private-yellow-900-solid: rgb(255, 236, 206);
  --g-color-private-yellow-950-solid: rgb(255, 242, 222);
  --g-color-private-yellow-1000-solid: rgb(255, 245, 231);
  --g-color-private-orange-50: rgba(200, 99, 12, 0.1);
  --g-color-private-orange-100: rgba(200, 99, 12, 0.15);
  --g-color-private-orange-150: rgba(200, 99, 12, 0.2);
  --g-color-private-orange-200: rgba(200, 99, 12, 0.3);
  --g-color-private-orange-250: rgba(200, 99, 12, 0.4);
  --g-color-private-orange-300: rgba(200, 99, 12, 0.5);
  --g-color-private-orange-350: rgba(200, 99, 12, 0.6);
  --g-color-private-orange-400: rgba(200, 99, 12, 0.7);
  --g-color-private-orange-450: rgba(200, 99, 12, 0.8);
  --g-color-private-orange-500: rgba(200, 99, 12, 0.9);
  --g-color-private-orange-50-solid: rgb(51, 36, 32);
  --g-color-private-orange-100-solid: rgb(59, 40, 31);
  --g-color-private-orange-150-solid: rgb(67, 43, 30);
  --g-color-private-orange-200-solid: rgb(84, 50, 27);
  --g-color-private-orange-250-solid: rgb(100, 57, 25);
  --g-color-private-orange-300-solid: rgb(117, 64, 23);
  --g-color-private-orange-350-solid: rgb(134, 71, 21);
  --g-color-private-orange-400-solid: rgb(150, 78, 19);
  --g-color-private-orange-450-solid: rgb(167, 85, 16);
  --g-color-private-orange-500-solid: rgb(183, 92, 14);
  --g-color-private-orange-550-solid: rgb(200, 99, 12);
  --g-color-private-orange-600-solid: rgb(206, 115, 36);
  --g-color-private-orange-650-solid: rgb(211, 130, 61);
  --g-color-private-orange-700-solid: rgb(217, 146, 85);
  --g-color-private-orange-750-solid: rgb(222, 161, 109);
  --g-color-private-orange-800-solid: rgb(228, 177, 134);
  --g-color-private-orange-850-solid: rgb(233, 193, 158);
  --g-color-private-orange-900-solid: rgb(239, 208, 182);
  --g-color-private-orange-950-solid: rgb(244, 224, 206);
  --g-color-private-orange-1000-solid: rgb(247, 232, 219);
  --g-color-private-red-50: rgba(229, 50, 93, 0.1);
  --g-color-private-red-100: rgba(229, 50, 93, 0.15);
  --g-color-private-red-150: rgba(229, 50, 93, 0.2);
  --g-color-private-red-200: rgba(229, 50, 93, 0.3);
  --g-color-private-red-250: rgba(229, 50, 93, 0.4);
  --g-color-private-red-300: rgba(229, 50, 93, 0.5);
  --g-color-private-red-350: rgba(229, 50, 93, 0.6);
  --g-color-private-red-400: rgba(229, 50, 93, 0.7);
  --g-color-private-red-450: rgba(229, 50, 93, 0.8);
  --g-color-private-red-500: rgba(229, 50, 93, 0.9);
  --g-color-private-red-50-solid: rgb(54, 31, 40);
  --g-color-private-red-100-solid: rgb(63, 32, 43);
  --g-color-private-red-150-solid: rgb(73, 33, 46);
  --g-color-private-red-200-solid: rgb(93, 35, 52);
  --g-color-private-red-250-solid: rgb(112, 37, 58);
  --g-color-private-red-300-solid: rgb(132, 40, 64);
  --g-color-private-red-350-solid: rgb(151, 42, 69);
  --g-color-private-red-400-solid: rgb(171, 44, 75);
  --g-color-private-red-450-solid: rgb(190, 46, 81);
  --g-color-private-red-500-solid: rgb(210, 48, 87);
  --g-color-private-red-550-solid: rgb(229, 50, 93);
  --g-color-private-red-600-solid: rgb(232, 71, 109);
  --g-color-private-red-650-solid: rgb(234, 91, 125);
  --g-color-private-red-700-solid: rgb(237, 112, 142);
  --g-color-private-red-750-solid: rgb(239, 132, 158);
  --g-color-private-red-800-solid: rgb(242, 153, 174);
  --g-color-private-red-850-solid: rgb(245, 173, 190);
  --g-color-private-red-900-solid: rgb(247, 194, 206);
  --g-color-private-red-950-solid: rgb(250, 214, 223);
  --g-color-private-red-1000-solid: rgb(251, 224, 231);
  --g-color-private-purple-50: rgba(143, 82, 204, 0.1);
  --g-color-private-purple-100: rgba(143, 82, 204, 0.15);
  --g-color-private-purple-150: rgba(143, 82, 204, 0.2);
  --g-color-private-purple-200: rgba(143, 82, 204, 0.3);
  --g-color-private-purple-250: rgba(143, 82, 204, 0.4);
  --g-color-private-purple-300: rgba(143, 82, 204, 0.5);
  --g-color-private-purple-350: rgba(143, 82, 204, 0.6);
  --g-color-private-purple-400: rgba(143, 82, 204, 0.7);
  --g-color-private-purple-450: rgba(143, 82, 204, 0.8);
  --g-color-private-purple-500: rgba(143, 82, 204, 0.9);
  --g-color-private-purple-50-solid: rgb(45, 34, 51);
  --g-color-private-purple-100-solid: rgb(50, 37, 60);
  --g-color-private-purple-150-solid: rgb(56, 40, 68);
  --g-color-private-purple-200-solid: rgb(67, 45, 85);
  --g-color-private-purple-250-solid: rgb(78, 50, 102);
  --g-color-private-purple-300-solid: rgb(89, 56, 119);
  --g-color-private-purple-350-solid: rgb(99, 61, 136);
  --g-color-private-purple-400-solid: rgb(110, 66, 153);
  --g-color-private-purple-450-solid: rgb(121, 71, 170);
  --g-color-private-purple-500-solid: rgb(132, 77, 187);
  --g-color-private-purple-550-solid: rgb(143, 82, 204);
  --g-color-private-purple-600-solid: rgb(154, 99, 209);
  --g-color-private-purple-650-solid: rgb(165, 117, 214);
  --g-color-private-purple-700-solid: rgb(177, 134, 219);
  --g-color-private-purple-750-solid: rgb(188, 151, 224);
  --g-color-private-purple-800-solid: rgb(199, 169, 230);
  --g-color-private-purple-850-solid: rgb(210, 186, 235);
  --g-color-private-purple-900-solid: rgb(221, 203, 240);
  --g-color-private-purple-950-solid: rgb(233, 220, 245);
  --g-color-private-purple-1000-solid: rgb(238, 229, 247);
  --g-color-private-cool-grey-50: rgba(96, 128, 156, 0.1);
  --g-color-private-cool-grey-100: rgba(96, 128, 156, 0.15);
  --g-color-private-cool-grey-150: rgba(96, 128, 156, 0.2);
  --g-color-private-cool-grey-200: rgba(96, 128, 156, 0.3);
  --g-color-private-cool-grey-250: rgba(96, 128, 156, 0.4);
  --g-color-private-cool-grey-300: rgba(96, 128, 156, 0.5);
  --g-color-private-cool-grey-350: rgba(96, 128, 156, 0.6);
  --g-color-private-cool-grey-400: rgba(96, 128, 156, 0.7);
  --g-color-private-cool-grey-450: rgba(96, 128, 156, 0.8);
  --g-color-private-cool-grey-500: rgba(96, 128, 156, 0.9);
  --g-color-private-cool-grey-50-solid: rgb(40, 39, 46);
  --g-color-private-cool-grey-100-solid: rgb(43, 44, 52);
  --g-color-private-cool-grey-150-solid: rgb(46, 49, 58);
  --g-color-private-cool-grey-200-solid: rgb(53, 59, 71);
  --g-color-private-cool-grey-250-solid: rgb(59, 69, 83);
  --g-color-private-cool-grey-300-solid: rgb(65, 79, 95);
  --g-color-private-cool-grey-350-solid: rgb(71, 88, 107);
  --g-color-private-cool-grey-400-solid: rgb(77, 98, 119);
  --g-color-private-cool-grey-450-solid: rgb(84, 108, 132);
  --g-color-private-cool-grey-500-solid: rgb(90, 118, 144);
  --g-color-private-cool-grey-550-solid: rgb(96, 128, 156);
  --g-color-private-cool-grey-600-solid: rgb(112, 141, 166);
  --g-color-private-cool-grey-650-solid: rgb(128, 153, 176);
  --g-color-private-cool-grey-700-solid: rgb(144, 166, 186);
  --g-color-private-cool-grey-750-solid: rgb(160, 179, 196);
  --g-color-private-cool-grey-800-solid: rgb(176, 192, 206);
  --g-color-private-cool-grey-850-solid: rgb(191, 204, 215);
  --g-color-private-cool-grey-900-solid: rgb(207, 217, 225);
  --g-color-private-cool-grey-950-solid: rgb(223, 230, 235);
  --g-color-private-cool-grey-1000-solid: rgb(231, 236, 240);
  --g-color-text-primary: var(--g-color-text-light-primary);
  --g-color-text-complementary: var(--g-color-text-light-complementary);
  --g-color-text-secondary: var(--g-color-text-light-secondary);
  --g-color-text-hint: var(--g-color-text-light-hint);
  --g-color-text-info: var(--g-color-private-blue-550-solid);
  --g-color-text-positive: var(--g-color-private-green-550-solid);
  --g-color-text-warning: var(--g-color-private-yellow-550-solid);
  --g-color-text-danger: var(--g-color-private-red-550-solid);
  --g-color-text-utility: var(--g-color-private-purple-600-solid);
  --g-color-text-misc: var(--g-color-private-cool-grey-600-solid);
  --g-color-text-info-heavy: var(--g-color-private-blue-600-solid);
  --g-color-text-positive-heavy: var(--g-color-private-green-600-solid);
  --g-color-text-warning-heavy: var(--g-color-private-yellow-600-solid);
  --g-color-text-danger-heavy: var(--g-color-private-red-600-solid);
  --g-color-text-utility-heavy: var(--g-color-private-purple-650-solid);
  --g-color-text-misc-heavy: var(--g-color-private-cool-grey-650-solid);
  --g-color-text-brand: var(--g-color-private-yellow-600-solid);
  --g-color-text-brand-heavy: var(--g-color-private-yellow-700-solid);
  --g-color-text-brand-contrast: var(--g-color-text-dark-primary);
  --g-color-text-link: var(--g-color-private-yellow-550-solid);
  --g-color-text-link-hover: var(--g-color-private-orange-550-solid);
  --g-color-text-link-visited: var(--g-color-private-purple-600-solid);
  --g-color-text-link-visited-hover: var(--g-color-private-purple-750-solid);
  --g-color-text-dark-primary: var(--g-color-private-black-900);
  --g-color-text-dark-complementary: var(--g-color-private-black-700);
  --g-color-text-dark-secondary: var(--g-color-private-black-500);
  --g-color-text-dark-hint: var(--g-color-private-black-300);
  --g-color-text-light-primary: var(--g-color-private-white-850);
  --g-color-text-light-complementary: var(--g-color-private-white-700);
  --g-color-text-light-secondary: var(--g-color-private-white-500);
  --g-color-text-light-hint: var(--g-color-private-white-300);
  --g-color-text-inverted-primary: var(--g-color-text-dark-primary);
  --g-color-text-inverted-complementary: var(--g-color-text-dark-complementary);
  --g-color-text-inverted-secondary: var(--g-color-text-dark-secondary);
  --g-color-text-inverted-hint: var(--g-color-text-dark-hint);
  --g-color-base-background: rgb(34, 29, 34);
  --g-color-base-generic: var(--g-color-private-white-100);
  --g-color-base-generic-hover: var(--g-color-private-white-150);
  --g-color-base-generic-medium: var(--g-color-private-white-250);
  --g-color-base-generic-medium-hover: var(--g-color-private-white-300);
  --g-color-base-generic-accent: var(--g-color-private-white-150);
  --g-color-base-generic-accent-disabled: var(--g-color-private-white-70);
  --g-color-base-generic-ultralight: var(--g-color-private-white-20-solid);
  --g-color-base-simple-hover: var(--g-color-private-white-100);
  --g-color-base-simple-hover-solid: var(--g-color-private-white-100-solid);
  --g-color-base-brand: var(--g-color-private-yellow-550-solid);
  --g-color-base-brand-hover: var(--g-color-private-yellow-650-solid);
  --g-color-base-selection: var(--g-color-private-yellow-150);
  --g-color-base-selection-hover: var(--g-color-private-yellow-200);
  --g-color-base-info-light: var(--g-color-private-blue-150);
  --g-color-base-info-light-hover: var(--g-color-private-blue-200);
  --g-color-base-info-medium: var(--g-color-private-blue-300);
  --g-color-base-info-medium-hover: var(--g-color-private-blue-400);
  --g-color-base-info-heavy: var(--g-color-private-blue-600-solid);
  --g-color-base-info-heavy-hover: var(--g-color-private-blue-700-solid);
  --g-color-base-positive-light: var(--g-color-private-green-150);
  --g-color-base-positive-light-hover: var(--g-color-private-green-200);
  --g-color-base-positive-medium: var(--g-color-private-green-300);
  --g-color-base-positive-medium-hover: var(--g-color-private-green-400);
  --g-color-base-positive-heavy: var(--g-color-private-green-600-solid);
  --g-color-base-positive-heavy-hover: var(--g-color-private-green-700-solid);
  --g-color-base-warning-light: var(--g-color-private-yellow-150);
  --g-color-base-warning-light-hover: var(--g-color-private-yellow-200);
  --g-color-base-warning-medium: var(--g-color-private-yellow-300);
  --g-color-base-warning-medium-hover: var(--g-color-private-yellow-400);
  --g-color-base-warning-heavy: var(--g-color-private-yellow-600-solid);
  --g-color-base-warning-heavy-hover: var(--g-color-private-yellow-700-solid);
  --g-color-base-danger-light: var(--g-color-private-red-150);
  --g-color-base-danger-light-hover: var(--g-color-private-red-200);
  --g-color-base-danger-medium: var(--g-color-private-red-300);
  --g-color-base-danger-medium-hover: var(--g-color-private-red-400);
  --g-color-base-danger-heavy: var(--g-color-private-red-600-solid);
  --g-color-base-danger-heavy-hover: var(--g-color-private-red-700-solid);
  --g-color-base-utility-light: var(--g-color-private-purple-150);
  --g-color-base-utility-light-hover: var(--g-color-private-purple-250);
  --g-color-base-utility-medium: var(--g-color-private-purple-300);
  --g-color-base-utility-medium-hover: var(--g-color-private-purple-400);
  --g-color-base-utility-heavy: var(--g-color-private-purple-600-solid);
  --g-color-base-utility-heavy-hover: var(--g-color-private-purple-700-solid);
  --g-color-base-neutral-light: var(--g-color-private-white-100);
  --g-color-base-neutral-light-hover: var(--g-color-private-white-150);
  --g-color-base-neutral-medium: var(--g-color-private-white-250);
  --g-color-base-neutral-medium-hover: var(--g-color-private-white-350);
  --g-color-base-neutral-heavy: var(--g-color-private-white-550);
  --g-color-base-neutral-heavy-hover: var(--g-color-private-white-650);
  --g-color-base-misc-light: var(--g-color-private-cool-grey-150);
  --g-color-base-misc-light-hover: var(--g-color-private-cool-grey-200);
  --g-color-base-misc-medium: var(--g-color-private-cool-grey-300);
  --g-color-base-misc-medium-hover: var(--g-color-private-cool-grey-400);
  --g-color-base-misc-heavy: var(--g-color-private-cool-grey-600-solid);
  --g-color-base-misc-heavy-hover: var(--g-color-private-cool-grey-700-solid);
  --g-color-base-light: var(--g-color-private-white-850);
  --g-color-base-light-hover: var(--g-color-private-white-700);
  --g-color-base-light-simple-hover: var(--g-color-private-white-150);
  --g-color-base-light-disabled: var(--g-color-private-white-150);
  --g-color-base-light-accent-disabled: var(--g-color-private-white-300);
  --g-color-base-float: var(--g-color-private-white-100-solid);
  --g-color-base-float-hover: var(--g-color-private-white-150-solid);
  --g-color-base-float-medium: var(--g-color-private-white-150-solid);
  --g-color-base-float-heavy: var(--g-color-private-white-250-solid);
  --g-color-base-float-accent: var(--g-color-private-white-150-solid);
  --g-color-base-float-accent-hover: var(--g-color-private-white-200-solid);
  --g-color-base-float-announcement: var(--g-color-private-white-150-solid);
  --g-color-base-modal: var(--g-color-base-background);
  --g-color-line-generic: var(--g-color-private-white-150);
  --g-color-line-generic-hover: var(--g-color-private-white-250);
  --g-color-line-generic-active: var(--g-color-private-white-300);
  --g-color-line-generic-accent: var(--g-color-private-white-150);
  --g-color-line-generic-accent-hover: var(--g-color-private-white-300);
  --g-color-line-generic-solid: var(--g-color-private-white-150-solid);
  --g-color-line-brand: var(--g-color-private-yellow-600-solid);
  --g-color-line-focus: var(--g-color-private-cool-grey-450);
  --g-color-line-light: var(--g-color-private-white-500);
  --g-color-line-info: var(--g-color-private-blue-450);
  --g-color-line-positive: var(--g-color-private-green-450);
  --g-color-line-warning: var(--g-color-private-yellow-450);
  --g-color-line-danger: var(--g-color-private-red-450);
  --g-color-line-utility: var(--g-color-private-purple-450);
  --g-color-line-misc: var(--g-color-private-cool-grey-450);
  --g-color-sfx-veil: var(--g-color-private-black-600);
  --g-color-sfx-shadow: var(--g-color-private-black-200);
  --g-color-sfx-shadow-heavy: var(--g-color-private-black-500);
  --g-color-sfx-shadow-light: var(--g-color-private-black-200);
  --g-color-sfx-fade: var(--g-color-private-white-250);
  --g-color-scroll-track: var(--g-color-base-background);
  --g-color-scroll-handle: var(--g-color-private-white-150);
  --g-color-scroll-handle-hover: var(--g-color-private-white-250);
  --g-color-scroll-corner: var(--g-color-private-white-150);
  --g-color-infographics-axis: var(--g-color-private-white-150-solid);
  --g-color-infographics-tooltip-bg: var(--g-color-private-white-opaque-150);
  --g-color-private-brand-50: rgba(108,145,201,0.1);
  --g-color-private-brand-100: rgba(108,145,201,0.15);
  --g-color-private-brand-150: rgba(108,145,201,0.2);
  --g-color-private-brand-200: rgba(108,145,201,0.3);
  --g-color-private-brand-250: rgba(108,145,201,0.4);
  --g-color-private-brand-300: rgba(108,145,201,0.5);
  --g-color-private-brand-350: rgba(108,145,201,0.6);
  --g-color-private-brand-400: rgba(108,145,201,0.7);
  --g-color-private-brand-450: rgba(108,145,201,0.8);
  --g-color-private-brand-500: rgba(108,145,201,0.9);
  --g-color-private-brand-550-solid: rgb(108,145,201);
  --g-color-private-brand-1000-solid: rgb(233,239,247);
  --g-color-private-brand-950-solid: rgb(226,233,244);
  --g-color-private-brand-900-solid: rgb(211,222,239);
  --g-color-private-brand-850-solid: rgb(196,211,233);
  --g-color-private-brand-800-solid: rgb(182,200,228);
  --g-color-private-brand-750-solid: rgb(167,189,223);
  --g-color-private-brand-700-solid: rgb(152,178,217);
  --g-color-private-brand-650-solid: rgb(137,167,212);
  --g-color-private-brand-600-solid: rgb(123,156,206);
  --g-color-private-brand-500-solid: rgb(101,133,184);
  --g-color-private-brand-450-solid: rgb(93,122,168);
  --g-color-private-brand-400-solid: rgb(86,110,151);
  --g-color-private-brand-350-solid: rgb(78,99,134);
  --g-color-private-brand-300-solid: rgb(71,87,118);
  --g-color-private-brand-250-solid: rgb(64,75,101);
  --g-color-private-brand-200-solid: rgb(56,64,84);
  --g-color-private-brand-150-solid: rgb(49,52,67);
  --g-color-private-brand-100-solid: rgb(45,46,59);
  --g-color-private-brand-50-solid: rgb(41,41,51);
  --g-color-base-brand: rgb(108,145,201);
  --g-color-base-background: rgb(34,29,34);
  --g-color-base-brand-hover: var(--g-color-private-brand-650-solid);
  --g-color-base-selection: var(--g-color-private-brand-150);
  --g-color-base-selection-hover: var(--g-color-private-brand-200);
  --g-color-line-brand: var(--g-color-private-brand-600-solid);
  --g-color-text-brand: var(--g-color-private-brand-600-solid);
  --g-color-text-brand-heavy: var(--g-color-private-brand-700-solid);
  --g-color-text-brand-contrast: rgb(255, 255, 255);
  --g-color-text-link: var(--g-color-private-brand-550-solid);
  --g-color-text-link-hover: var(--g-color-private-brand-700-solid);
  --g-color-text-link-visited: var(--g-color-private-purple-700-solid);
  --g-color-text-link-visited-hover: var(--g-color-private-purple-850-solid);
}

