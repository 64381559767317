.info-field-container {
    display: flex;
    flex-direction: row;
    padding: 8px 12px;
    background-color: #f5f5f5;
    border-radius: 8px;
    width : '100%';
    flex: 1;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  
  .field-label {
    font-size: 0.75rem;
    color: #888;
    margin-bottom: 0px;

  }
  
  .info-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .field-value {
    font-size: 1rem;
    color: #333;
  }
  
  .copy-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #888;
    font-size: 10rem;
    display: flex;
    align-items: center;
  }
  
  .copy-button:hover {
    color: #333;
  }