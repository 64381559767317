body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gn-aside-header {
  position: fixed;
}


 
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d8d8; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c0c0c0; 
}

.gn-aside-header__content {
  position: relative;
}

.top-toaster {
  top: 0;
  right: 0;
  height:0;
  padding:'30px';
}