.color-choose:hover {
    position: relative;
    cursor: pointer;
}

.color-choose:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(157, 157, 157, 0.5);
    border-radius: 50%;
    z-index: 1;
}
   