@use '../../node_modules/@gravity-ui/uikit/styles/themes';

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');



.g-root {
    @include themes.g-theme-common;
    
    
    --g-font-family-sans: 'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    --g-font-family-monospace: 'Roboto Mono', 'Menlo', 'Monaco', 'Consolas', 'Ubuntu Mono', 'Liberation Mono', 'DejaVu Sans Mono', 'Courier New', 'Courier', monospace;
    
    
    &_theme_light {
        @include themes.g-theme-light;
        --gn-aside-header-decoration-expanded-background-color: var(--g-color-private-brand-100);
        --gn-aside-header-decoration-collapsed-background-color: var(--g-color-private-brand-300);
        --gn-aside-header-item-current-background-color: var(--g-color-private-brand-200);
        --g-color-private-brand-50: rgba(108,145,201,0.1);
        --g-color-private-brand-100: rgba(108,145,201,0.15);
        --g-color-private-brand-150: rgba(108,145,201,0.2);
        --g-color-private-brand-200: rgba(108,145,201,0.3);
        --g-color-private-brand-250: rgba(108,145,201,0.4);
        --g-color-private-brand-300: rgba(108,145,201,0.5);
        --g-color-private-brand-350: rgba(108,145,201,0.6);
        --g-color-private-brand-400: rgba(108,145,201,0.7);
        --g-color-private-brand-450: rgba(108,145,201,0.8);
        --g-color-private-brand-500: rgba(108,145,201,0.9);
        --g-color-private-brand-550-solid: rgb(108,145,201);
        --g-color-private-brand-1000-solid: rgb(45,46,59);
        --g-color-private-brand-950-solid: rgb(49,52,67);
        --g-color-private-brand-900-solid: rgb(56,64,84);
        --g-color-private-brand-850-solid: rgb(64,75,101);
        --g-color-private-brand-800-solid: rgb(71,87,118);
        --g-color-private-brand-750-solid: rgb(78,99,134);
        --g-color-private-brand-700-solid: rgb(86,110,151);
        --g-color-private-brand-650-solid: rgb(93,122,168);
        --g-color-private-brand-600-solid: rgb(101,133,184);
        --g-color-private-brand-500-solid: rgb(123,156,206);
        --g-color-private-brand-450-solid: rgb(137,167,212);
        --g-color-private-brand-400-solid: rgb(152,178,217);
        --g-color-private-brand-350-solid: rgb(167,189,223);
        --g-color-private-brand-300-solid: rgb(182,200,228);
        --g-color-private-brand-250-solid: rgb(196,211,233);
        --g-color-private-brand-200-solid: rgb(211,222,239);
        --g-color-private-brand-150-solid: rgb(226,233,244);
        --g-color-private-brand-100-solid: rgb(233,239,247);
        --g-color-private-brand-50-solid: rgb(240,244,250);
        
        
        --g-color-base-brand: rgb(108,145,201);
        --g-color-base-background: rgb(255,255,255);
        --g-color-base-brand-hover: var(--g-color-private-brand-600-solid);
        --g-color-base-selection: var(--g-color-private-brand-200);
        --g-color-base-selection-hover: var(--g-color-private-brand-300);
        --g-color-line-brand: var(--g-color-private-brand-600-solid);
        --g-color-text-brand: var(--g-color-private-brand-700-solid);
        --g-color-text-brand-heavy: var(--g-color-private-brand-700-solid);
        --g-color-text-brand-contrast: rgb(255, 255, 255);
        --g-color-text-link: var(--g-color-private-brand-600-solid);
        --g-color-text-link-hover: var(--g-color-private-orange-800-solid);
        --g-color-text-link-visited: var(--g-color-private-purple-550-solid);
        --g-color-text-link-visited-hover: var(--g-color-private-purple-800-solid);
    }

    &_theme_dark {
        @include themes.g-theme-dark;

       --g-color-private-brand-50: rgba(108,145,201,0.1);
        --g-color-private-brand-100: rgba(108,145,201,0.15);
        --g-color-private-brand-150: rgba(108,145,201,0.2);
        --g-color-private-brand-200: rgba(108,145,201,0.3);
        --g-color-private-brand-250: rgba(108,145,201,0.4);
        --g-color-private-brand-300: rgba(108,145,201,0.5);
        --g-color-private-brand-350: rgba(108,145,201,0.6);
        --g-color-private-brand-400: rgba(108,145,201,0.7);
        --g-color-private-brand-450: rgba(108,145,201,0.8);
        --g-color-private-brand-500: rgba(108,145,201,0.9);
        --g-color-private-brand-550-solid: rgb(108,145,201);
        --g-color-private-brand-1000-solid: rgb(233,239,247);
        --g-color-private-brand-950-solid: rgb(226,233,244);
        --g-color-private-brand-900-solid: rgb(211,222,239);
        --g-color-private-brand-850-solid: rgb(196,211,233);
        --g-color-private-brand-800-solid: rgb(182,200,228);
        --g-color-private-brand-750-solid: rgb(167,189,223);
        --g-color-private-brand-700-solid: rgb(152,178,217);
        --g-color-private-brand-650-solid: rgb(137,167,212);
        --g-color-private-brand-600-solid: rgb(123,156,206);
        --g-color-private-brand-500-solid: rgb(101,133,184);
        --g-color-private-brand-450-solid: rgb(93,122,168);
        --g-color-private-brand-400-solid: rgb(86,110,151);
        --g-color-private-brand-350-solid: rgb(78,99,134);
        --g-color-private-brand-300-solid: rgb(71,87,118);
        --g-color-private-brand-250-solid: rgb(64,75,101);
        --g-color-private-brand-200-solid: rgb(56,64,84);
        --g-color-private-brand-150-solid: rgb(49,52,67);
        --g-color-private-brand-100-solid: rgb(45,46,59);
        --g-color-private-brand-50-solid: rgb(41,41,51);
        
        
        --g-color-base-brand: rgb(108,145,201);
        --g-color-base-background: rgb(34,29,34);
        --g-color-base-brand-hover: var(--g-color-private-brand-650-solid);
        --g-color-base-selection: var(--g-color-private-brand-150);
        --g-color-base-selection-hover: var(--g-color-private-brand-200);
        --g-color-line-brand: var(--g-color-private-brand-600-solid);
        --g-color-text-brand: var(--g-color-private-brand-600-solid);
        --g-color-text-brand-heavy: var(--g-color-private-brand-700-solid);
        --g-color-text-brand-contrast: rgb(255, 255, 255);
        --g-color-text-link: var(--g-color-private-brand-550-solid);
        --g-color-text-link-hover: var(--g-color-private-brand-700-solid);
        --g-color-text-link-visited: var(--g-color-private-purple-700-solid);
        --g-color-text-link-visited-hover: var(--g-color-private-purple-850-solid);
    }
}